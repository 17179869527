.sidebarHandler {
  color: white !important;
  width: 20px;
  min-width: 20px !important;
  max-width: 20px !important;
  padding: 0px !important;
  display: flex;
  height: 100%;
  border-radius: 0 !important;
  background: #141414 !important;
  text-align: center;
}
