.plantInfo {
  width: 90%;
  margin: 5% auto 0;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.createDevice {
  border-top: var(--primary_border);
  padding-top: 5%;
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
}

.MuiInput-root::after {
  border-bottom-color: var(--dark_secondary-color) !important;
}
.input * {
  color: var(--white-color) !important;
}
.MuiInput-input.Mui-disabled {
  -webkit-text-fill-color: #aaa !important;
  border-bottom: 1px #aaa !important;
  border-bottom-style: solid !important;
}
.disabledInput {
  pointer-events: none !important;
}
