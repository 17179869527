.add-dashboard {
  max-width: 200px;
}
.dashboardsContainer {
  margin: 0 !important;
  width: 100% !important;
  padding: 10px !important;
  display: flex;
  align-items: flex-start;
  height: var(--workspace-height);
}
.scrollable::-webkit-scrollbar {
  width: 8px;
  border-radius: 3px;
  background-color: var(--primary_background-color);
}
.scrollable::-webkit-scrollbar-thumb {
  background-color: var(--grey-color);
  border-radius: 2px;
}
.dashboard {
  cursor: pointer;
  height: fit-content;
  padding: 0 !important;
  padding-bottom: 10px !important;
}
.dashboardLoadingContainer {
  height: var(--workspace-height);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.addContainer {
  min-height: 33.33vh;
}
.add {
  color: white;
}
.add:first-child {
  height: 100px;
  width: 100px;
}
.addDashboardButton {
  display: flex !important;
  padding: 8px 15px !important;
  width: 150px;
  color: #fff !important;
  background-color: var(--primary_background-color);
  border: 1px solid #888 !important;
  background: "var(--primary_background-color)";
}
@media (min-width: 1025px) {
  .dashboardsContainer {
    padding: 15px !important;
  }
  .dashboard {
    padding: 20px !important;
  }
}
