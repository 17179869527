
.scroll-box {
  overflow-y: auto;
  height: 200px;
}
.description {
  width: 100%;
  max-height: 100px;
  border-radius: 3px;
}
.description textarea {
  min-height: 62px;
}
.MuiColorInput-Button {
  width: 20px !important;
  height: 20px !important;
  border: 1px solid #555 !important;
}

.dragHandleBtn {
  width: 100%;
  color: #999 !important;
  background: #555 !important;
  cursor: grab !important;
}
.colorPickerLabel {
  padding-left: 12px;
  color: var(--black-color);
  font-size: 16px;
}
.infoBoxWrapper {
  border-radius: 3px;
  background-color: var(--primary_background-color);
  border: var(--primary_border);
}

.dashboardTitleContainer {
  display: flex;
  align-items: center;
}
.header {
  position: relative;
}

.imageButton {
  position: relative;
  cursor: pointer;
}

.imageButton:hover .imagePreview {
  display: block;
}
