.editDashboardLeftWrapper {
  background-color: var(--primary-color) !important;
  border-right: var(--primary_border);
  height: 100%;
}

.serviceTableContainer {
  max-height: 50%;
}

.componentTab {
  margin-bottom: 15% !important;
}

.accordionSummary,
.accordionDetails {
  border-bottom: var(--primary_border) !important;
}

.accordionDetails {
  background-color: var(--grey-color) !important;
  padding-left: 0;
  padding-right: 0;
}

.accordionSummary {
  color: var(--light_grey-color) !important;
  background-color: var(--dark_grey-color) !important;
}

.MuiAccordionSummary-expandIconWrapper {
  color: var(--light_grey-color) !important;
}

.MuiAccordionSummary-expandIconWrapper {
  color: var(--light_grey-color) !important;
}

.accordionSummary .Mui-expanded {
  color: var(--secondary-color) !important;
}

.MuiAccordion-root.Mui-expanded {
  margin: 0 !important;
  border-top: 1px solid #121212;
}