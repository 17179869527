.loading_overlay_container{
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.loading-overlay{
  background: rgba(0, 0, 0, 0.6);
}
.loading{
  transform: translate(1.5%, 6%)
}

@media (max-width: 850px){
  .loading{
    transform: translate(1%, 9%)
  }
}