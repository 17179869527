.gridContainer {
  background-image: linear-gradient(to right, #3e3e3e 1px, transparent 1px), linear-gradient(to bottom, #3e3e3e 1px, transparent 1px);
  position: relative;
  background-color: #595959;
  overflow-x: hidden !important;
}

.moveMe {
  box-shadow:
  2.2px 2.8px 2.2px #88888843,
  5.3px 6.7px 5.3px #88888843,
  10px 12.5px 10px #88888843,
  17.9px 22.3px 17.9px #88888843
}
