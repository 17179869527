.componentInfo {
  font-weight: bold;
  margin-left: 20px;
}

.inputWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  border-radius: 3px;
}

.borderTop {
  border-bottom: 1px solid var(--grey-color) !important;
  width: 43.5%;
  height: 1px;
  margin: 0 1%;
}

.titleRow * {
  background-color: var(--primary_background-color) !important;
  color: var(--secondary-color) !important;
}

.serviceName {
  width: 20% !important;
}

.actionsMenu {
  width: 20% !important;
}

.servicesTable {
  width: 100%;
}

.servicesTable th, .servicesTable td {
  text-align: center;
  border-bottom: none;
  min-width: 50%;
}

.servicesTable th {
  color: inherit;
}

.servicesTable tbody {
  border: 1px solid var(--grey_background-color);
}

.servicesTable tbody tr {
  display: flex;
  justify-content: center;
  background-color: var(--primary_background-color);
  padding: 5px;
}

.servicesTable tbody tr:last-child {
  justify-content: end;
}

.servicesTable td {
  color: var(--light_grey-color);
  padding: 0;
}

.servicesTable tbody tr:last-child td {
  text-align: end;
  width: auto;
}