#new-window-container {
  overflow: hidden;
  height: 100%;
  width: 100%;
  display: flex;
  background: #ccc;
}
.fakePhoneSpk {
  width: 33%;
  height: 4%;
  background: var(--primary-color);
  position: absolute;
  left: 33%;
  right: 0;
  top: 2%;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
}
.workspace {
  background-image: linear-gradient(to right, #3e3e3e 1px, transparent 1px),
    linear-gradient(to bottom, #3e3e3e 1px, transparent 1px);
  position: relative;
  background-color: #595959;
}
