.componentInput {
  width: 97%;
  padding-left: 2%;
}
.componentInputContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  height: 55px;
  border-left: 1px solid var(--grey-color);
  border-right: 1px solid var(--grey-color);
  background-color: var(--primary_background-color);
}
.componentInputContainer input {
  padding-left: 7px !important;
}
.componentInputLabel {
  position: absolute;
  top: 10%;
  left: 4%;
  font-size: 10px;
  color: #ccc;
  text-transform: capitalize;
}
.input * {
  font-size: 14px !important;
}
.componentSelect {
  border-bottom: 1px solid var(--grey-color) !important;
}
.componentSelect * {
  color: #fff !important;
  border-color: transparent !important;
  font-size: 10px;
}
.componentSelect .MuiSelect-select {
  font-size: 14px !important;
}
.MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused {
  color: #ccc !important;
}
