.mainContainer {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../../public/domotica.jpg");
  background-color: black;
  background-size: 70%;
  opacity: 1;
  background-repeat: no-repeat;
  background-position: center;
}
.loading {
  opacity: 1;
  transform: translate(1.5%, 6%);
}

@media (max-width: 850px) {
  .loading {
    transform: translate(1%, 9%);
  }
}
