.MuiFilledInput-root:after {
  border-bottom-color: var(--secondary_background-color) !important;
}
.logoK {
  height: 65px;
  width: 250px;
  margin-left: 40px;
}
.MuiCard-root.loginCard {
  background: linear-gradient(
    0deg,
    #595959 0%,
    var(--grey_background-color) 100%
  );
  border-radius: 10px;
  max-width: 300px;
  margin: auto;
  border: 0;
}

.MuiButton-root.standardButton {
  border-radius: 7px;
  width: 150px;
  background-color: var(--secondary_background-color);
}

.loginHeader {
  color: var(--white-color);
  background-color: var(--black-color);
  border-bottom: solid 1px var(--white-color);
}

.loginLogo {
  height: 56px;
  width: 200px;
  max-height: 56px;
  max-width: 256px;
}

.MuiInputLabel-root.loginInput {
  color: var(--black-color);
}

.loginInput fieldset {
  color: var(--black-color);
  border-color: var(--black-color) !important;
}

.loginInput .MuiFormLabel-root {
  color: var(--black-color) !important;
  border-color: var(--black-color) !important;
}

.MuiFilledInput-input.loginInput {
  border: 1px solid var(--black-color);
  border-radius: 5px;
  background-color: transparent;
}

.MuiOutlinedInput-root.Mui-focused.loginInput {
  background-color: transparent;
  border-color: var(--black-color);
}

.showPassIcon {
  position: absolute;
  bottom: 30%;
  display: flex;
  right: 5%;
  cursor: pointer;
}
.pswLabel {
  transform: (0px, 16px) scale(1) !important;
}
.loginBodyBackground {
  background-image: url("../../public/domotica.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

#email-input, #password-input {
  text-indent: 10px
}

#email-input {
  background-color: transparent !important;
}

#email-input:-webkit-autofill,
#email-input:-webkit-autofill:hover,
#email-input:-webkit-autofill:focus,
#email-input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #8d8d8d inset !important;
}
#password-input:-webkit-autofill,
#password-input:-webkit-autofill:hover,
#password-input:-webkit-autofill:focus,
#password-input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #838383 inset !important;
  margin-left: -8px;
}
