/**************************/
/* K-ARRAY TAB COMPONENT */
/************************/

/* main container */
.K-ARRAY-TAB {
  width: 95%;
  margin: 0 auto;
  min-height: 200px;
  max-height: 200px;
  /* removed for component drag and drop overflow-y: auto; */
  overflow: visible;
  box-sizing: border-box;
  border-radius: 4px;
  border: var(--primary_border);
}
/* tab container */
.K-ARRAY-TAB .MuiTabs-flexContainer {
  border-bottom: var(--primary_border) !important;
}
/*** target odd childs for border right ***/
.K-ARRAY-TAB .MuiTabs-flexContainer > :nth-child(1) {
  border: none;
  border-right: var(--primary_border) !important;
}
.K-ARRAY-TAB .MuiTabs-flexContainer > :nth-child(3) {
  border-right: var(--primary_border);
}
.K-ARRAY-TAB .MuiTabs-flexContainer > :nth-child(5) {
  border-right: var(--primary_border);
}
/******/
/*** tab width ***/
.K-ARRAY-TAB .MuiTab-root.double {
  width: 50% !important;
}
.K-ARRAY-TAB .MuiTab-root.triple {
  width: 33% !important;
}
.K-ARRAY-TAB .MuiTab-root.quad {
  width: 25% !important;
}

/******/
/* background of list line and on hover */
.K-ARRAY-TAB .MuiListItem-root {
}
/* Tab color */
.K-ARRAY-TAB .MuiTab-root {
  margin: 0 !important;
}
/* padding sets the width of li elements */
.K-ARRAY-TAB .MuiTabPanel-root,
.K-ARRAY-TAB .MuiList-root {
  padding: 0 !important;
}
/* UNSELECTED tab color */
/*  the color */
.K-ARRAY-TAB .MuiTab-root {
  color: aliceblue;
}
/* SELECTED tab color */
.K-ARRAY-TAB .MuiTab-root.Mui-selected {
  color: var(--secondary-color) !important;
}
.K-ARRAY-TAB .MuiTabs-indicator {
  background-color: var(--dark_secondary-color) !important;
}
/* list item */
.K-ARRAY-TAB .MuiListItemButton-root:hover {
  background-color: var(--dark_secondary-color) !important;
}
.K-ARRAY-TAB .MuiListItemButton-root {
  color: black !important;
  height: 40px !important;
  padding: 0 !important;
  border-bottom: 1px solid var(--dark_secondary-color) !important;
  background-color: #222;
}
/* list item buttons */
.K-ARRAY-TAB .MuiAvatar-root,
.MuiListItemSecondaryAction-root button {
  height: 25px !important;
  width: 25px !important;
  padding: 0;
  margin: 0;
}
/* list item img */
.K-ARRAY-TAB .MuiListItemAvatar-root {
  min-width: 40px;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
/* list item svgs */
.K-ARRAY-TAB .MuiAvatar-root svg,
.K-ARRAY-TAB .MuiListItemSecondaryAction-root svg {
  height: 18px;
  width: 18px;
}
.K-ARRAY-TAB .MuiListItemSecondaryAction-root svg {
  color: var(--secondary-color);
}
.K-ARRAY-TAB .MuiAvatar-root svg {
  color: var(--black-color);
}
/* list item label */
.K-ARRAY-TAB .MuiListItemText-root span {
  font-size: 15px !important;
  line-height: 18px !important;
  text-transform: uppercase !important;
  color: aliceblue !important;
}
/* list item description */
.K-ARRAY-TAB .MuiListItemText-root p {
  font-size: 11px !important;
  color: aliceblue !important;
}
/************************/

/****************************/
/* K-ARRAY TABLE COMPONENT */
/**************************/
.K-ARRAY-TABLE .table th,
.K-ARRAY-TABLE .table td,
.K-ARRAY-TABLE .table tr {
  color: var(--white-color) !important;
  border: solid 1px var(--grey-color) !important;
}
.K-ARRAY-TABLE .selectedDashboard {
  pointer-events: none;
  background-color: var(--dark_secondary-color);
}
.K-ARRAY-TABLE .unselectedDashboard {
  background-color: var(--primary_background-color) !important;
}
.K-ARRAY-TABLE .selectedDashboard > :nth-child(1) {
  border-right-color: #ccc !important;
}
.K-ARRAY-TABLE .table td,
.K-ARRAY-TABLE .table tr {
  padding: 0;
}
.K-ARRAY-TABLE .MuiTableHead-root.lightthead th {
  background-color: var(--primary-color) !important;
  color: var(--secondary-color) !important;
}
/****/
.MuiTable-root.darkTable td,
.MuiTable-root.darkTable tr {
  color: var(--white-color);
  border: solid 1px #444;
}
.MuiTable-root.darkTable th {
  border-bottom: solid 1px #444;
}

/******************/
/* K-ARRAY SELECT */
/******************/
.MuiMenu-paper {
  background-color: #222 !important;
  border: var(--primary_border);
  color: var(--grey_background-color) !important;
  border-top: none;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
.MuiMenuItem-root:hover {
  background-color: var(--grey-color) !important;
  color: var(--primary-color) !important;
}
