.scrollable {
  overflow-y: auto;
}

.scrollable::-webkit-scrollbar {
  width: 8px;
  border-radius: 3px;
  background-color: var(--grey-color);

}
.scrollable::-webkit-scrollbar-thumb {
  background-color: var(--primary_background-color);
  border-radius: 2px;
}

.accordionSummary,
.accordionDetails {
  border-bottom: var(--primary_border) !important;
}

.accordionDetails {
  background-color: var(--grey-color) !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
