:root {
  /* Color Palette */
  --primary-color: #141414;
  --selected-color: #3e3e3e;
  --unselected-color: #121212;
  --primary_background-color: #212121;
  --secondary_background-color: #333;
  --primary_button-color: #fff;
  --white-color: #fff;
  --black-color: #000;
  --grey-color: #373636;
  --light_grey-color: #ccc;
  --dark_grey-color: #2e2e2e;
  --grey_background-color: #c1c1c1f0;
  --secondary-color: #bd9d68;
  --dark_secondary-color: #735e3b;

  /* Heights */
  /* header + breadcrumb + borders */
  --workspace-height: calc(100% - calc(var(--header-heigth) + var(--breadcrumb-height) + 3px));
  --header-heigth: 50px;
  --breadcrumb-height: 40px;
  --draggable-button-height: 22.5px;
  /* Utilities */
  --primary_border: 1px solid var(--grey-color);
}

/* Common Classes*/
.center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.col {
  display: flex;
  flex-direction: column !important;
}

.no-underline-title {
  text-transform: uppercase;
  position: relative;
  color: var(--secondary-color);
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 2px;
  text-align: center;
  -webkit-font-smoothing: antialiased;
}

.underline-title {
  text-transform: uppercase;
  text-align: center;
  position: relative;
  color: var(--secondary-color);
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 2px;
  margin-bottom: 30px;
  -webkit-font-smoothing: antialiased;
}

.underline-title::after {
  content: "";
  position: absolute;
  height: 2px;
  width: 25%;
  left: 37.5%;
  background-color: var(--dark_secondary-color);
  bottom: -5px;
}

.main-title {
  margin-top: 5%;
}

.text-overflow {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}


.scrollable {
  overflow-y: auto;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  -webkit-border-radius: 1px;
  background-color: var(--selected-color);
  border-radius: 1px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 1px;
  border-radius: 1px;
  background-color: var(--grey_background-color);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: #666;
}

.truncateTxt{
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}

/*  */
html {
  height: 100vh;
}

body,
#root,
.App {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--black-color);
  height: 100%;
  width: 100%;
  overflow: hidden;
  /* OVERFLOW AGGIUNTO SENZA TEST */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Containers */
.homeWrapper {
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
}

.workspaceWrapper {
  /* overflow borders */
  height: calc(100% - var(--header-heigth));
  color: var(--white-color);
  background-color: var(--primary-color);
  border-radius: 0;
  z-index: 1;
  overflow: auto;
  box-sizing: border-box;
}

.editdashboardWrapper {
  overflow-x: hidden;
}

.innerworkspaceWrapper,
.editdashboardWrapper,
.installationMainWrapper,
.loadingContainer,
.dashboardsEditCenterWrapper {
  height: 100%;
  overflow-y: auto;
}

.loadingContainer {
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
}

.dashboardsEditCenterWrapper {
  display: flex;
}

.MuiPaper-root.dashboardsWrapper {
  background-color: var(--primary_background-color);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 100%;
  min-width: 100%;
}

.MuiPaper-root.viewComponentsComandsWrapper {
  color: var(--white-color);
  background-color: var(--primary-color);
  border: var(--primary_border);
}

.MuiPaper-root.sidebar {
  height: 100%;
  color: var(--white-color);
  background-color: var(--primary-color);
  border-radius: 0;
  z-index: 1;
  overflow: auto;
  box-sizing: border-box;
}

.MuiPaper-root.installationWrapper {
  background-color: var(--primary_background-color);
  border-radius: 3px;
  min-height: var(--workspace-height);
  max-height: var(--workspace-height);
  overflow: auto !important;
}

.MuiPaper-root.componentsWrapperList {
  background-color: var(--primary_background-color);
  min-height: calc(var(--workspace-height) - 400px);
  max-height: calc(var(--workspace-height) - 50px);
}

.MuiAppBar-root.headerWrapper {
  background: var(--primary-color);
  border-bottom: solid 2px var(--white-color);
  min-height: var(--header-heigth);
  display: flex;
  flex-direction: row;
}

.MuiButton-root.primaryButton:hover {
  color: var(--secondary-color);
}

.searchInput * {
  color: var(--white-color) !important;
  border-color: #6e6e6e !important;
}

.MuiButton-root.sidebarButton,
.MuiButton-root.sidebarButton:hover {
  min-width: 80%;
  color: var(--black-color);
  background-color: var(--primary_button-color);
  border: var(--primary_border);
}

.MuiButton-root.upperViewbarButton,
.MuiButton-root.upperViewbarButton:hover {
  min-height: 50px;
  border: none;
  color: var(--white-color);
  width: 100%;
}

.MuiButton-root.componentViewbarButton,
.MuiButton-root.componentViewbarButton:hover {
  min-height: 50px;
  border: none;
  color: var(--white-color);
  width: 100%;
}

.MuiInputLabel-root.searchnInput {
  color: var(--white-color);
}

.searchInput fieldset {
  color: var(--white-color);
  border: var(--primary_border);
}

.searchInput .MuiFormLabel-root {
  color: var(--white-color) !important;
  border-color: var(--white-color) !important;
}

.MuiFilledInput-input.searchInput {
  border: 1px solid var(--white-color);
  border-radius: 5px;
  background-color: transparent;
}

.MuiOutlinedInput-root.Mui-focused.searchInput {
  background-color: transparent;
  border-color: var(--white-color);
}

.maxwidth100 {
  max-width: 100%;
}

.headerLogo {
  width: 190px;
  height: 50px;
}

.searchBarLine {
  border-color: var(--white-color);
  width: 30%;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}

.searchBoxWrapper,
.createPartnership {
  border: var(--primary_border);
  background-color: var(--secondary_background-color);
  border-radius: 1%;
}

.installationsBarLine {
  border-color: var(--white-color);
  width: 30%;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}

.whiteIcon {
  color: var(--white-color) !important;
}

.selectInputLabel {
  color: var(--white-color) !important;
  background-color: transparent;
}

.selectInput {
  color: var(--white-color) !important;
}

.whiteCheckbox {
  color: var(--white-color) !important;
}

.greyCheckbox {
  color: var(--grey-color) !important;
}

.dashboardNameInfo {
  padding: 4px;
  color: #333;
  background-color: var(--white-color) !important;
  border-radius: 4px;
}

.dashboardOrderInfo {
  padding: 4px 8px;
  color: #333;
  background-color: var(--white-color) !important;
  border-radius: 4px;
}

.MuiInput-root::before {
  border-bottom-color: #555 !important;
}

@media (min-width: 1025px) {

  .MuiPaper-root.leftbarWrapper,
  .MuiPaper-root.rightbarWrapper {
    overflow-x: hidden;
  }

  /*   .workspaceWrapper {
    overflow: hidden !important;
  } */
}